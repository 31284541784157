.full {
    background: url('/img/login-bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.login {
    width: 50%;
    align-self: center;
}
.login-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.height-100, .height-100 #app {
    height: 100%;
    overflow: hidden;
}
.text-logo-dark-green {
    color: #074C00;
}
.force-text-logo-dark-green {
    color: #074C00 !important;
}
.background-logo-dark-green {
    background-color: #074C00;
}
.force-background-logo-dark-green {
    background-color: #074C00 !important;
}
.text-logo-green {
    color: #0c7f00;
}
.force-text-logo-green {
    color: #0c7f00 !important;
}
.background-logo-green {
    background-color: #0c7f00;
}
.force-background-logo-green {
    background-color: #0c7f00 !important;
}
.navbar-brand .navbar-brand-image {
    height: 62px;
    position: absolute;
    top:-6px;
    left:0px;
}
.navbar-brand {
    font-weight: 500;
    color: #0c7f00;
    position: relative;
    padding-left:64px;
}
.navbar-default .navbar-brand {
    color: #0c7f00;
}

.affix {
    top:50px;
    position:fixed;
}

.emblem {
    border: 1px solid #666;
    width: 18px;
    height: 18px;
    text-align: center;
    font-size: 12px;
    display: inline-block;
}
.fullpp .emblem {
    width: 12px;
    height: 12px;
    font-size: 8px;
}

.emblem.green {
    border: 1px solid #40d613;
    color: #40d613;
}

.numberCircle {
    border-radius: 50%;
 }
.numberSquare {
    /*padding: .5px 2px;*/
}
.word {
    font-size: 10px;
}
.uppercase {
    text-transform: uppercase;
}
.On {
    padding: 1px 17px 1px 1px;
}
.Off {
    padding: 1px 22px 1px 1px;
}
.table-condensed.really-condensed > tbody > tr > td {
    padding: 5px 3px;
}
body.fullpp .table>tbody>tr>td {
    border: none;
}
body.fullpp .table-condensed.really-condensed > tbody > tr > td,
body.fullpp .table-condensed.really-condensed > thead > tr > td {
    padding: 0px 3px;
}
.currentPoc {
    background-color: #bada55;
}
.mr-2 {
    margin-right: 2rem;
}
.ml-2 {
    margin-left: 2rem;
}
.flex {
    display: flex;
}
.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}
.space-evenly {
    justify-content: space-evenly;
}
body {overflow-x: hidden;}

.inline-block {
    display: inline-block;
    margin-left: 1px;
    font-size: 11px;
}
.diamond-shape {
    height: 10px;
    width: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    border: 1px solid #666;
}
.letter {
    transform: rotate(-45deg);
    margin-left: 0px;
    margin-top: 0px;
    position: absolute;
    top: -4px;
    left: 1px;
    font-size: 10px;
}
.turf-green {
    color: #32CD32;
}
